import { template as template_a78042bba7bd4b79bc8dcedfb35ede38 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a78042bba7bd4b79bc8dcedfb35ede38(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
