import { template as template_69cfe9b7f1cd47bb90c4511a6e1a6ef9 } from "@ember/template-compiler";
const WelcomeHeader = template_69cfe9b7f1cd47bb90c4511a6e1a6ef9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
