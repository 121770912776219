import { template as template_e05b05c740a6432b80d5a00ac96a77df } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_e05b05c740a6432b80d5a00ac96a77df(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
